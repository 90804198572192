import * as React from 'react';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import requestUrlInfo from 'js/lib/requestUrlInfo';
import { useLocation } from 'js/lib/useRouter';

import type { CourseraMetatagsProps, LinkInfo, OverrideRule } from 'bundles/seo/CourseraMetatagsV2Types';
import SeoPathConfigurationQueryWrapper from 'bundles/seo/api/SeoPathConfigurationQueryWrapper';
import { SEO_OVERRIDE_ENABLED_APPS } from 'bundles/seo/common/constants';
import { useSeoPageData } from 'bundles/seo/contexts/SeoPageDataContext';
import { generateHreflangLinks, generateLinkTagsForPaginatedPages } from 'bundles/seo/utils/pageLinks';
import {
  generateMetaNameAndProperties,
  generateMetaTitle,
  generateOverrideRuleFromMetaTags,
} from 'bundles/seo/utils/pageMetadata';

declare const COURSERA_APP_NAME: string;

const CourseraMetatagsBaseComponent = (props: CourseraMetatagsProps) => {
  const location = useLocation();
  const { metaTags: metaTagsFromSeoPageData } = useSeoPageData();
  const { metaNameAndProperties, hrefLangOverrides, paginationProperties = {} } = props;
  const { title, disableCourseraSuffix } = metaNameAndProperties;

  const helmetMeta = useMemo(() => {
    let overrideRule: OverrideRule | undefined;
    if (metaTagsFromSeoPageData) {
      overrideRule = generateOverrideRuleFromMetaTags(metaTagsFromSeoPageData);
    }

    const metaTitle = generateMetaTitle(title, disableCourseraSuffix, overrideRule);

    const hrefLangLinks: Array<LinkInfo> = generateHreflangLinks(
      location,
      hrefLangOverrides,
      metaTagsFromSeoPageData?.hreflangLinks ?? undefined
    );
    const paginatedLinkTagObjects = generateLinkTagsForPaginatedPages(paginationProperties, location, overrideRule);
    const hreflagsAreEmptyAndNotOverridden = !hrefLangOverrides && hrefLangLinks.length === 0;

    const generatedMetaNameAndProperties = generateMetaNameAndProperties(
      {
        ...metaNameAndProperties,
        disableCrawlerIndexing:
          metaNameAndProperties.enableCrawlerIndexing !== undefined
            ? !metaNameAndProperties.enableCrawlerIndexing
            : hreflagsAreEmptyAndNotOverridden || metaNameAndProperties.disableCrawlerIndexing,
      },
      location,
      overrideRule
    );

    return {
      title: metaTitle,
      meta: generatedMetaNameAndProperties,
      link: [...hrefLangLinks, ...paginatedLinkTagObjects],
    };
  }, [
    disableCourseraSuffix,
    hrefLangOverrides,
    location,
    metaNameAndProperties,
    metaTagsFromSeoPageData,
    paginationProperties,
    title,
  ]);
  return <Helmet {...helmetMeta} />;
};

const CourseraMetatags = (props: CourseraMetatagsProps) => {
  const { pathname, search } = useLocation();
  const hostname = requestUrlInfo().hostname;

  // Only fetch page data overrides for apps in the list
  const enabledAppList = SEO_OVERRIDE_ENABLED_APPS;
  const appName = COURSERA_APP_NAME;

  return (
    <SeoPathConfigurationQueryWrapper
      path={hostname + pathname + search}
      shouldSkip={!enabledAppList.includes(appName)}
    >
      <CourseraMetatagsBaseComponent {...props} />
    </SeoPathConfigurationQueryWrapper>
  );
};

export default CourseraMetatags;

export const BaseComponent = CourseraMetatagsBaseComponent;
