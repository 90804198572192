import type { Seo_MetaTags as SeoMetaTags } from '__generated__/graphql-types-do-not-use';
import intersection from 'lodash/intersection';

import { urlPathLocaleSubfolderPatternMatcher } from 'bundles/internationalization-lib/utils/subfolderPatternMatcher';
import type { MetaNameAndPropertiesProps, OverrideRule, SeoLocation } from 'bundles/seo/CourseraMetatagsV2Types';
import { blackListedSubdomains, blackListedUrlParameters, defaultImageHref } from 'bundles/seo/common/constants';
import {
  META_DESC_MAX_LEN,
  TWITTER_DESC_MAX_LEN,
  getSubdomainDetails,
  isProductPrimaryLanguageOnSupportedLanguageDomain,
  limitDescriptionLength,
  removeLineBreakFromString,
} from 'bundles/seo/utils/domainAndMetadataUtils';

import _t from 'i18n!nls/seo';

function getMetaDescription(
  props: MetaNameAndPropertiesProps,
  overrideRule: OverrideRule | undefined,
  limit?: number
): string {
  if (overrideRule?.description && Object.prototype.hasOwnProperty.call(overrideRule, 'description')) {
    return overrideRule.description;
  }

  const { disableDescLimit = false, description, descriptionSuffix, disableCourseraDescriptionSuffix = false } = props;

  const defaultDescription = _t(
    'Learn online and earn valuable credentials from top universities like Yale, Michigan, Stanford, and leading companies like Google and IBM. Join Coursera for free and transform your career with degrees, certificates, Specializations, & MOOCs in data science, computer science, business, and dozens of other topics.'
  );

  const newDescription = description || '';
  const combinedDescription = disableCourseraDescriptionSuffix
    ? newDescription
    : `${newDescription} ${defaultDescription}`;

  const combinedDescriptionWithoutLineBreaks = removeLineBreakFromString(combinedDescription);
  const combinedDescriptionWithLengthLimit = limitDescriptionLength(combinedDescriptionWithoutLineBreaks, limit);
  const combinedDescriptionWithLengthLimitOrLimitWithSuffix = descriptionSuffix
    ? `${combinedDescriptionWithLengthLimit} ${descriptionSuffix}`
    : combinedDescriptionWithLengthLimit;

  return description && !disableDescLimit
    ? combinedDescriptionWithLengthLimitOrLimitWithSuffix
    : combinedDescriptionWithoutLineBreaks.trim();
}

const checkIfPageHasBlackListedUrlParams = (pageUrlParams: Array<string>): boolean => {
  return Boolean(intersection(blackListedUrlParameters, pageUrlParams).length);
};

export function generateMetaTitle(
  title: string | undefined | null,
  disableCourseraSuffix: boolean | undefined = false,
  overrideRule: OverrideRule | undefined
): string {
  if (overrideRule?.title && Object.prototype.hasOwnProperty.call(overrideRule, 'title')) {
    return overrideRule.title;
  }
  const defaultMetaTitle = _t('Coursera | Online Courses & Credentials From Top Educators. Join for Free');
  return disableCourseraSuffix
    ? title || defaultMetaTitle
    : _t('#{title} | Coursera', { title: title || defaultMetaTitle });
}

export const generateRobotsTag = (
  props: MetaNameAndPropertiesProps,
  location: SeoLocation,
  overrideRule?: OverrideRule
) => {
  const {
    absolutePageHref,
    disableCrawlerIndexing = false,
    useFollowForDisablingIndexing = false,
    productPrimaryLanguage,
  } = props;

  const { pageSubdomainAndPathname, pageSubdomain } = getSubdomainDetails(location, absolutePageHref);

  const pageHasBlackListedUrlParams = checkIfPageHasBlackListedUrlParams(Object.keys(location.query ?? {}));

  // We want to disable crawler indexing for product pages with primary languages that does not match the subdomain https://coursera.atlassian.net/browse/GR-21513
  const productPrimaryLanguageOnWrongSubdomain =
    productPrimaryLanguage &&
    pageSubdomainAndPathname &&
    !isProductPrimaryLanguageOnSupportedLanguageDomain(productPrimaryLanguage, pageSubdomainAndPathname.subdomain);

  const defaultDisableCrawlerIndexingBehaviour =
    // This means 'www-origin' and international subdomains are auto disabled to avoid duplicate page entries
    (pageSubdomain && blackListedSubdomains.includes(pageSubdomain)) ||
    // We also want to disable indexing for pages that have the auth modal params in its URL e.g https://www.coursera.org?authMode=login
    pageHasBlackListedUrlParams ||
    productPrimaryLanguageOnWrongSubdomain ||
    disableCrawlerIndexing;

  const shouldDisableCrawlerIndexing =
    overrideRule?.disableCrawlerIndexing !== undefined
      ? overrideRule.disableCrawlerIndexing
      : defaultDisableCrawlerIndexingBehaviour;

  const useFollowDisableIndex =
    overrideRule?.useFollowForDisablingIndexing !== undefined
      ? overrideRule.useFollowForDisablingIndexing
      : useFollowForDisablingIndexing;

  const shouldDisableCrawlerFollow =
    overrideRule?.disableCrawlerFollow !== undefined
      ? overrideRule.disableCrawlerFollow
      : shouldDisableCrawlerIndexing && !useFollowDisableIndex;

  const shouldDisableCrawlerAI = overrideRule?.disableCrawlerAI !== undefined ? overrideRule.disableCrawlerAI : false;

  const contentList = [];

  if (shouldDisableCrawlerIndexing) contentList.push('noindex');

  if (shouldDisableCrawlerFollow) {
    contentList.push('nofollow');
  } else if (shouldDisableCrawlerIndexing) {
    // If we added noindex but we aren't disabling crawler follow, add the follow
    contentList.push('follow');
  }

  if (shouldDisableCrawlerAI) contentList.push('noai');

  return contentList.length > 0
    ? {
        name: 'robots',
        content: contentList.join(', '),
      }
    : undefined;
};

export function generateMetaNameAndProperties(
  props: MetaNameAndPropertiesProps,
  location: SeoLocation,
  overrideRule: OverrideRule | undefined
): Array<{ property?: string; content?: string; name?: string }> {
  const {
    absolutePageHref,
    enableViewportFitCover,
    ogAndTwitterTitle,
    twitterCardWithLargeImage,
    title,
    disableCourseraSuffix = false,
    absoluteImageHref = defaultImageHref,
    descriptionLengthLimit = META_DESC_MAX_LEN,
  } = props;

  // TODO(jzhao): deprecate 'js/lib/metatags' usage
  const metaDescription = getMetaDescription(props, overrideRule, descriptionLengthLimit);
  const twitterMetaDescription = getMetaDescription(props, overrideRule, TWITTER_DESC_MAX_LEN);
  // Every meta tag here should also be in page/components/MetatagsWrapper.jsx, where the default is set
  const metaNameAndProperties = [
    { name: 'description', content: metaDescription },
    { name: 'image', content: absoluteImageHref },
    {
      property: 'og:title',
      content: ogAndTwitterTitle || generateMetaTitle(title, disableCourseraSuffix, overrideRule),
    },
    { property: 'og:description', content: metaDescription },
    { property: 'og:image', content: absoluteImageHref },
    {
      property: 'twitter:title',
      content: ogAndTwitterTitle || generateMetaTitle(title, disableCourseraSuffix, overrideRule),
    },
    { property: 'twitter:description', content: twitterMetaDescription },
    { property: 'twitter:image:src', content: absoluteImageHref },
    { property: 'twitter:image', content: absoluteImageHref },
    { property: 'twitter:card', content: twitterCardWithLargeImage ? 'summary_large_image' : 'summary' },
  ];

  const localeOverride = urlPathLocaleSubfolderPatternMatcher(location.pathname)?.languageAndLocale;
  if (localeOverride) {
    metaNameAndProperties.push({
      property: 'og:locale',
      content: localeOverride,
    });
  }

  if (enableViewportFitCover) {
    metaNameAndProperties.push({
      property: 'viewport',
      content: 'width=device-width, initial-scale=1, viewport-fit=cover',
    });
  }

  const robotsTag = generateRobotsTag(props, location, overrideRule);

  if (robotsTag !== undefined) {
    metaNameAndProperties.push(robotsTag);
  }

  if (absolutePageHref) {
    metaNameAndProperties.push({ property: 'og:url', content: absolutePageHref });
  }

  return metaNameAndProperties;
}

export const generateOverrideRuleFromMetaTags = (metaTagsFromSeoPageData: SeoMetaTags): OverrideRule => {
  const { canonicalURL, disableCrawlerIndexing, descriptionTag, titleTag, disableCrawlerFollow, disableCrawlerAI } =
    metaTagsFromSeoPageData;
  return {
    disableCrawlerIndexing: disableCrawlerIndexing ?? undefined,
    disableCrawlerFollow: disableCrawlerFollow ?? undefined,
    disableCrawlerAI: disableCrawlerAI ?? undefined,
    title: titleTag ?? undefined,
    description: descriptionTag ?? undefined,
    canonicalLinkHrefOverride: canonicalURL ?? undefined,
  };
};

export const forTesting = {
  getMetaDescription,
  checkIfPageHasBlackListedUrlParams,
};
