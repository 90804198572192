import * as React from 'react';

import { getCopy } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { CourseraMetatags } from 'bundles/seo';
import type { CourseraMetatagsProps } from 'bundles/seo/types';

import _t from 'i18n!nls/coursera-plus-landing-page';

const CourseraPlusHubPageMetatags = (props: CourseraMetatagsProps) => {
  const { numberOfProducts } = getCopy();
  const metaTitle = _t('Coursera Plus | Unlimited Access to #{numberOfProducts} Online Courses', {
    numberOfProducts,
  });
  const metaDescription = _t(
    'Invest in your professional goals with Coursera Plus. Get Unlimited access to over 90% of courses, Projects, Specializations, and Professional Certificates.'
  );

  return (
    <CourseraMetatags
      metaNameAndProperties={{
        title: metaTitle,
        description: metaDescription,
        disableCourseraSuffix: true,
        disableCrawlerIndexing: props.disableCrawlerIndexing,
      }}
    />
  );
};

export default CourseraPlusHubPageMetatags;
