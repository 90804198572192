import type { Seo_HreflangLink as SeoHreflangLink } from '__generated__/graphql-types-do-not-use';
import URI from 'jsuri';

import config from 'js/app/config';

import type {
  HreflangOverrides,
  LinkInfo,
  OverrideRule,
  PaginationPropertiesProps,
  SeoLocation,
} from 'bundles/seo/CourseraMetatagsV2Types';
import { getPageUrl } from 'bundles/seo/utils/domainAndMetadataUtils';

const uri = new URI(config.url.base);
const hostWithoutSubdomain = uri.host().split('.').slice(1).join('.');

function generateHref(location: SeoLocation, subdomain: string): string {
  const { pathname } = location;
  const newUri = new URI(`https://${subdomain}.${hostWithoutSubdomain}`).setPath(pathname);
  return newUri.toString();
}

const defaultHrefLangOverrides = (links: Array<LinkInfo>, location: SeoLocation) => {
  const { query = {}, hash } = location;

  // URLs with hash and queries shall not have hreflang
  if (hash || Object.keys(query).length) {
    return [];
  }
  return links;
};

export function generateHreflangLinks(
  location: SeoLocation,
  hrefLangOverrides: HreflangOverrides = defaultHrefLangOverrides,
  hreflangLinksFromSeoPageData?: SeoHreflangLink[]
): LinkInfo[] {
  if (hreflangLinksFromSeoPageData) {
    return hreflangLinksFromSeoPageData.map(({ href, hreflang }) => ({ href, hreflang, rel: 'alternate' })) ?? [];
  }

  const allHreflang = [
    {
      rel: 'alternate',
      hreflang: 'x-default',
      href: generateHref(location, 'www'),
    },
    {
      rel: 'alternate',
      hreflang: 'en',
      href: generateHref(location, 'www'),
    },
  ];

  if (hrefLangOverrides) return hrefLangOverrides(allHreflang, location);

  return allHreflang;
}

/**
 * This applies to pages with pagination. It helps SEO in the following ways.
 * 1. Consolidate indexing properties, such as links, from the component pages/URLs to the series as a whole (that is, links should not remain dispersed between page-1.html, page-2.html, etc., but be grouped with the sequence).
 * 2. Send users to the most relevant page/URL—typically the first page of the series.
 * More details here https://developers.google.com/search/blog/2011/09/pagination-with-relnext-and-relprev
 *
 * @param props
 * @param location
 * @param overrideRule
 * @returns A list of link objects.
 */
export function generateLinkTagsForPaginatedPages(
  {
    absolutePageHref,
    absolutePrevPageHref,
    absoluteNextPageHref,
    canonicalLinkHrefOverride,
    productLocale,
  }: PaginationPropertiesProps,
  location: SeoLocation,
  overrideRule?: OverrideRule
): Array<LinkInfo> {
  const linkRelationAndProperties: Array<LinkInfo> = [];
  const pageURL = getPageUrl(absolutePageHref, location);

  // Determine the canonical link based on the product's primary language
  let primaryLanguageCanonicalLinkHref: string | undefined;

  if (productLocale && pageURL) {
    const newCanonicalUri = new URI(pageURL);
    const newCanonicalUrlHostName = uri.host();

    newCanonicalUri.setHost(newCanonicalUrlHostName);
    primaryLanguageCanonicalLinkHref = newCanonicalUri.toString();
  }

  // Overrides preceed the product canonical link
  const canonicalLinkHref =
    overrideRule?.canonicalLinkHrefOverride || canonicalLinkHrefOverride || primaryLanguageCanonicalLinkHref;

  if (canonicalLinkHref) {
    linkRelationAndProperties.push({
      rel: 'canonical',
      href: canonicalLinkHref,
    });
  }

  if (absolutePrevPageHref) {
    linkRelationAndProperties.push({ rel: 'prev', href: absolutePrevPageHref });
  }

  if (absoluteNextPageHref) {
    linkRelationAndProperties.push({ rel: 'next', href: absoluteNextPageHref });
  }
  return linkRelationAndProperties;
}

export const forTesting = {
  generateHref,
};
